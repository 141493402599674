import React from "react";
import { Box, Heading, Text } from "grommet";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { timeWithTz } from "../../helpers/timeWithTz";

const InfoSection = () => {
  const currentUserSelection = useSelector(
    (state) => state.current.bookingInfo
  );

  return (
    <Box direction="column" gap="medium">
      <Box direction="column">
        <Heading
          level="4"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          Service
        </Heading>
        <Text>{currentUserSelection.serviceName}</Text>
      </Box>
      {/* <Box direction="column">
        <Text>
          {bookingDuration(
            currentUserSelection.startTime,
            currentUserSelection.endTime
          )}
        </Text>
      </Box> */}
      <Box direction="column">
        <Heading
          level="4"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          Total Investment
        </Heading>
        <Text>${currentUserSelection.currentPrice}</Text>
      </Box>

      <Box direction="column">
        <Heading
          level="4"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          Date
        </Heading>
        <Text>
          {DateTime.fromSeconds(currentUserSelection.startTime).toLocaleString(
            DateTime.DATE_HUGE
          )}
        </Text>
      </Box>

      <Box direction="column">
        <Heading
          level="4"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          Time
        </Heading>
        <Text>
          {timeWithTz(currentUserSelection.startTime, currentUserSelection.tz)}{" "}
          - {timeWithTz(currentUserSelection.endTime, currentUserSelection.tz)}
        </Text>
      </Box>
      <Box direction="column">
        <Heading
          level="4"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          Location
        </Heading>
        <Text>{currentUserSelection.location}</Text>
      </Box>
      {currentUserSelection.hasOwnProperty("addOnList") &&
        currentUserSelection?.addOnList.length !== 0 && (
          <Box direction="column">
            <Heading
              level="4"
              margin={{
                vertical: "0",
                horizontal: "0",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
              }}
            >
              Add On
            </Heading>
            {currentUserSelection?.addOnList.map((item) => {
              return (
                <Text key={item.id}>
                  {item.name} - ${item.price}
                </Text>
              );
            })}
          </Box>
        )}
    </Box>
  );
};

export default InfoSection;
