import React, { useEffect, useState } from "react";
import { Box, Carousel, Image, ResponsiveContext, ThemeContext } from "grommet";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../../firebase";
import LoadingView from "./LoadingView";

const PhotoGallery = () => {
  const [loading, setLoading] = useState(true);
  const [gallery, setGallery] = useState([]);
  const [logo, setLogo] = useState();
  const size = React.useContext(ResponsiveContext);

  useEffect(() => {
    const getLogoImage = async () => {
      const storageRef = ref(
        storage,
        "ivana-steven-photography/public/IS_Logo_FullBlack-Color.png"
      );

      const imageUrl = await getDownloadURL(storageRef);
      setLogo(imageUrl);
    };

    if (!logo) {
      getLogoImage();
    }
  }, [logo]);

  useEffect(() => {
    setGallery([]);
    const galleryRef = ref(
      storage,
      "ivana-steven-photography/public/photo-gallery"
    );

    listAll(galleryRef)
      .then((gref) => {
        gref.items.forEach((itRef) => {
          getDownloadURL(itRef).then((url) => {
            setGallery((g) => [...g, url]);
          });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading & (gallery.length > 0) ? (
        <LoadingView />
      ) : (
        <ThemeContext.Extend
          value={{
            box: {
              extend: `position: relative;`,
            },
          }}
        >
          <Box
            height="large"
            width="large"
            fill={size === "small" ? true : "horizontal"}
          >
            <ThemeContext.Extend
              value={{
                box: {
                  extend: `position: absolute; z-index: 10000`,
                },
              }}
            >
              <Box
                width="medium"
                alignSelf="center"
                alignContent="center"
                animation="slideDown"
                margin={{ top: size === "small" ? "0.5em" : "0.5em" }}
              >
                <Image src={logo} />
              </Box>
            </ThemeContext.Extend>
            <Carousel play={5000} fill controls={false}>
              {gallery.map((slide, index) => {
                return (
                  <ThemeContext.Extend
                    key={index}
                    value={{
                      image: {
                        extend: () => `object-position: 0% 40%;`,
                      },
                    }}
                  >
                    <Image
                      key={index}
                      src={slide}
                      fit="cover"
                      opacity="strong"
                    />
                  </ThemeContext.Extend>
                );
              })}
            </Carousel>
          </Box>
        </ThemeContext.Extend>
      )}
    </>
  );
};

export default PhotoGallery;
