import React, { useEffect, useState } from "react";
import { Grommet } from "grommet";
import { customTheme } from "./theme";
import { Outlet } from "react-router-dom";

function App() {
  const [company, setCompany] = useState();

  useEffect(() => {
    const handleMessage = (evt) => {
      if (evt.data.company) {
        setCompany(evt.data.company);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  }, [setCompany]);

  console.log(company);

  return (
    // <Authenticator socialProviders={["google"]} signUpAttributes={["name"]}>
    // {({ signOut, user }) => (
    <Grommet theme={customTheme}>
      <Outlet />
    </Grommet>
    // )}
    // </Authenticator>
  );
}

export default App;
