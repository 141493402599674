import { useEffect } from "react";
import { firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { setCompInfo } from "../features/stateSlice";

export const useCompanyInfo = (compId) => {
  const dispatch = useDispatch();
  const currentInfo = useSelector((state) => state.current.companyInfo)
    ? true
    : false;

  useEffect(() => {
    async function callAPI() {
      const companyQuery = doc(firestore, "companies", compId);

      if (!currentInfo) {
        const companyData = await getDoc(companyQuery);

        if (companyData.exists()) {
          dispatch(setCompInfo(companyData.data()));
        }
      }
    }
    callAPI();
  });
};
