import { DateTime } from "luxon";

const timeFormat = { ...DateTime.TIME_SIMPLE, timeZoneName: "short" };

export const timeWithTz = (time, tz) => {
  const formattedTime = DateTime.fromSeconds(time, {
    zone: tz || "America/New_York",
  }).toLocaleString(timeFormat);

  return formattedTime;
};
