import React from "react";
import { Box, Text } from "grommet";

const ErrorPage = () => {
  return (
    <Box align="center" justify="center" fill pad="large">
      <Text>
        Oh no, our website hit a glitch! Please contact us for support!
      </Text>
    </Box>
  );
};

export default ErrorPage;
