import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Widget from "../Widget";
import { childRoutes } from "./child-routes";
import ErrorPage from "../components/Common/ErrorPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: childRoutes,
    errorElement: <ErrorPage />,
  },
  {
    path: "/widget",
    element: <Widget />,
    children: childRoutes,
  },
]);
