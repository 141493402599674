import React, { useState } from "react";
import { Anchor, Box, Button, CheckBox, Text } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircleInformation, Money, Secure } from "grommet-icons";
import { clearPaymentType, setConfirmation } from "../../features/paymentSlice";
import { firestore } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import TermsConditions from "./TermsConditions";

const CustomPaymentContent = ({ contentDetails }) => {
  const [openTC, setOpenTC] = useState(false);
  const [acceptTC, setAcceptTC] = useState(false);
  const navigate = useNavigate();
  const slotSelected = useSelector((state) => state.current.bookingInfo);
  const currentPerson = useSelector((state) => state.payment.bookingDetails);
  const companyInfo = useSelector((state) => state.current.companyInfo);

  const dispatch = useDispatch();

  const handleReserve = (e) => {
    e.preventDefault();

    dispatch(setConfirmation(true));

    setDoc(doc(firestore, "bookings", slotSelected.id), {
      bookingStatus: "new-unconfirmed",
      companyId: slotSelected.companyId,
      companyName: companyInfo.companyName,
      companyLogo: companyInfo.companyLogo,
      paymentMethod: contentDetails.method,
      paymentDirections: contentDetails.details,
      notes: currentPerson.details,
      fees: 0,
      location: slotSelected.locations,
      price: slotSelected.currentPrice,
      originalPrice: slotSelected.originalPrice,
      servUniqueId: slotSelected.servUniqueId,
      serviceName: slotSelected.serviceName,
      serviceId: slotSelected.id,
      startTime: DateTime.fromSeconds(slotSelected.startTime).toJSDate(),
      endTime: DateTime.fromSeconds(slotSelected.endTime).toJSDate(),
      customerEmail: currentPerson.email,
      customerName: currentPerson.name,
      customerPhone: currentPerson.phone,
      bookingDetails: slotSelected.bookingDetails,
      companyURL: companyInfo.companyURL,
      customerTz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      addOns: slotSelected?.addOnList || [],
    }).then(() => {
      navigate(`/confirm/${slotSelected.id}`);
    });
  };

  return (
    <Box direction="column" gap="small" margin="small">
      <Box gap="small" direction="row">
        <Money color="brand" />
        <Text>Amount: ${slotSelected.currentPrice}</Text>
      </Box>
      <Box gap="small" direction="row">
        <Secure color="brand" />
        <Text>{contentDetails.details}</Text>
      </Box>
      <Box gap="small" direction="row">
        <CircleInformation color="brand" />
        <Text>
          Spot will be held for 1 hour until payment is received. Payment
          confirmation will be sent to your email to officially secure your
          spot.
        </Text>
      </Box>
      <Box gap="small" direction="row">
        <CheckBox checked={acceptTC} onChange={() => setAcceptTC(!acceptTC)} />
        <Text weight="bold">
          I have read and agree to the{" "}
          <Anchor
            onClick={() => setOpenTC(true)}
            label="terms & conditions"
          ></Anchor>{" "}
          in order to book this session.
        </Text>
      </Box>

      <Button
        primary
        label="Reserve Spot for 1 Hour"
        onClick={(e) => handleReserve(e)}
        disabled={!acceptTC}
      />
      <Button
        secondary
        label="Change Payment Type"
        onClick={() => dispatch(clearPaymentType(null))}
      />
      {openTC && (
        <TermsConditions
          closeLayer={() => setOpenTC(false)}
          acceptCheck={() => setAcceptTC(true)}
        />
      )}
    </Box>
  );
};

export default CustomPaymentContent;
