import React from "react";
import { useSelector } from "react-redux";
import PaymentSelectionType from "./PaymentSelectionType";
import StripePaymentContent from "./StripePaymentContent";
import CustomPaymentContent from "./CustomPaymentContent";

const PaymentDetails = () => {
  const paymentType = useSelector((state) => state.payment.paymentState);
  const availPaymentOptions = useSelector(
    (state) => state.current.companyInfo?.paymentOptions
  );

  return (
    <>
      {!paymentType && <PaymentSelectionType />}
      {paymentType === "credit-card" && <StripePaymentContent />}
      {paymentType === "zelle" && (
        <CustomPaymentContent
          contentDetails={
            availPaymentOptions.filter((p) => p.method === "zelle")[0]
          }
        />
      )}
      {paymentType === "venmo" && (
        <CustomPaymentContent
          contentDetails={
            availPaymentOptions.filter((p) => p.method === "venmo")[0]
          }
        />
      )}
    </>
  );
};

export default PaymentDetails;
