import React from "react";
import { Button, Box, Text, ThemeContext } from "grommet";
import { selectBookingSlot } from "../../features/stateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { firestore } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

const AvailableTimeSlot = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const selectedDate = useSelector((state) => state.current.dateSelected);
  const durations = useSelector(
    (state) => state.current.serviceSelected.durations
  );
  const availTimes = useSelector((state) =>
    state.avails.filter(
      (item) =>
        DateTime.fromSeconds(item.startTime).toISODate() ===
        DateTime.fromISO(selectedDate).toISODate()
    )
  );

  const statusMessage = (message) => {
    switch (message) {
      case "available":
        return false;
      case "confirmed":
        return true;
      case "reserved":
        return true;
      case "pending":
        return true;
      case "available-private":
        return true;
      default:
        return;
    }
  };

  const updateStatus = async (docId, sessionTimer) => {
    const currentDocRef = doc(firestore, "availabilities", docId);
    // console.log("updating status: ", docId, sessionTimer);

    const result = await updateDoc(currentDocRef, {
      status: "pending",
      pendingTimer: sessionTimer,
    });

    console.log(result);
  };

  return availTimes.length > 0 ? (
    <Box gap="small">
      {availTimes.map((t) => (
        <Button
          key={t.id}
          disabled={t.status === "available" ? false : true}
          hoverIndicator="light-1"
          primary
          onClick={() => {
            const timer = DateTime.now().plus({ minutes: 5 }).toISO();
            updateStatus(t.id, timer)
              .then(() => {
                dispatch(
                  selectBookingSlot({
                    bookingInfo: {
                      ...t,
                      startTime: t.startTime,
                      endTime: t.endTime,
                      location: t.locations[0].name,
                      sessionTimer: timer,
                      bookingDetails: durations.find(
                        (d) => t.durationId === d.id
                      ).details,
                    },
                  })
                );
              })
              .then(() => {
                navigate("/review");
              });
          }}
          label={
            <Box pad="xsmall" direction="column" align="start" gap="small">
              <ThemeContext.Extend
                value={{
                  text: {
                    extend: statusMessage(t.status)
                      ? `text-decoration: line-through;`
                      : ``,
                  },
                }}
              >
                <Text>
                  {DateTime.fromSeconds(t.startTime).toLocaleString(
                    DateTime.TIME_SIMPLE
                  )}{" "}
                  - {t.durationId.replace("-", " ")}
                </Text>
                <Text>{t.locations[0].name}</Text>
              </ThemeContext.Extend>
            </Box>
          }
        />
      ))}
    </Box>
  ) : (
    <Text>No available slots.</Text>
  );
};

export default AvailableTimeSlot;
