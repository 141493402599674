import React from "react";
import { Box, Text } from "grommet";

const AdjustReservation = () => {
  return (
    <Box direction="column" align="center">
      <Text textAlign="center">
        Please <a href="mailto:hello@ivanasteven.com">contact us</a> for any
        adjustments or concerns about the session.
      </Text>
    </Box>
  );
};

export default AdjustReservation;
