import React from "react";
import { Box, Button, ThemeContext } from "grommet";

const ModalButton = ({ modalState }) => {
  return (
    <Box flex justify="end" align="end" alignSelf="end" pad="medium">
      <ThemeContext.Extend
        value={{
          button: {
            primary: {
              extend: `box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);`,
            },
          },
        }}
      >
        <Button primary label="Book now" onClick={() => modalState()} />
      </ThemeContext.Extend>
    </Box>
  );
};

export default ModalButton;
