import React from "react";
import { Box, ThemeContext } from "grommet";
import { Outlet } from "react-router-dom";

const ModalPopup = () => {
  return (
    <Box fill justify="end" align="end" pad="medium">
      <ThemeContext.Extend
        value={{
          button: {
            primary: {
              extend: `box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);`,
            },
          },
        }}
      >
        <Box elevation="small" round background="white-bg">
          <Outlet />
        </Box>
      </ThemeContext.Extend>
    </Box>
  );
};

export default ModalPopup;
