import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { calculateTimeLeft } from "../../helpers/calculateTimeLeft";
import { useSelector } from "react-redux";

const CountdownTimer = () => {
  const timeEnd = useSelector(
    (state) => state.current.bookingInfo?.sessionTimer
  );
  const typeSession = useSelector((state) => state.current.bookingInfo?.status);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(timeEnd));

  useEffect(() => {
    let timeout;

    if (Object.keys(calculateTimeLeft(timeEnd)).length !== 0) {
      timeout = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(timeEnd));
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  return (
    typeSession !== "available-private" && (
      <Box>
        <Text>
          {Object.keys(timeLeft).length !== 0
            ? `Booking appointment held for ${
                timeLeft.minutes
              }:${timeLeft.seconds.toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              })}`
            : `Booking appointment is no longer being held!`}
        </Text>
      </Box>
    )
  );
};

export default CountdownTimer;
