import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { firestore } from "../firebase";
import { deleteField, doc, updateDoc } from "firebase/firestore";

export function useBlocker(
  bookingId,
  bookingStatus,
  confirmationStatus,
  bookingData
) {
  let location = useLocation();
  const [block, setBlock] = useState(false);

  useEffect(() => {
    const currentDocRef = doc(firestore, "availabilities", bookingId);

    const updateStatus = async () => {
      await updateDoc(currentDocRef, {
        status: "available",
        pendingTimer: deleteField(),
      });
    };
    if (
      bookingStatus !== "available-private" &&
      !confirmationStatus &&
      bookingData
    ) {
      window.onpopstate = (e) => {
        updateStatus();
        setBlock(false);
      };
    }
  }, [location, bookingId, bookingStatus, confirmationStatus, bookingData]);

  useEffect(() => {
    if (
      bookingStatus !== "available-private" &&
      !confirmationStatus &&
      bookingData
    ) {
      window.addEventListener("beforeunload", alertUser);
      window.addEventListener("unload", handleEndConcert);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
        window.removeEventListener("unload", handleEndConcert);
        handleEndConcert();
      };
    }
  });

  const alertUser = async (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleEndConcert = async () => {
    const currentDocRef = doc(firestore, "availabilities", bookingId);

    await updateDoc(currentDocRef, {
      status: "available",
      pendingTimer: deleteField(),
    });
  };

  return block;
}
