import React from "react";
import { Box, Text, Heading } from "grommet";
import ReactHtmlParser from "react-html-parser";
import { DateTime } from "luxon";
import PaymentDetails from "./PaymentDetails";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { timeWithTz } from "../../helpers/timeWithTz";

export const BookingDetailsLeft = ({ serviceName, durationDetail }) => {
  return (
    <Box direction="column">
      <Text>Booking Type</Text>
      <Heading
        level="3"
        margin={{
          vertical: "0",
          horizontal: "0",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
        }}
      >
        {serviceName}
      </Heading>

      <Box>{ReactHtmlParser(durationDetail)}</Box>
    </Box>
  );
};

export const BookingDetailsRight = ({ bookingInfo }) => {
  return (
    <Box direction="column" gap="medium" align="center">
      <Box direction="column" align="center">
        <Text>Payment Status</Text>
        <Heading
          level="3"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          <PaymentDetails status={bookingInfo.bookingStatus} />
        </Heading>
      </Box>
      <Box direction="column" align="center">
        <Text>Booking Type</Text>
        <Heading
          level="3"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          {bookingInfo.serviceName}
        </Heading>
      </Box>
      <Box direction="column" align="center">
        <Text>Date</Text>
        <Heading
          level="3"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          {DateTime.fromSeconds(bookingInfo.startTime.seconds).toLocaleString(
            DateTime.DATE_HUGE
          )}
        </Heading>
      </Box>

      <Box direction="column" align="center">
        <Text>Time</Text>
        <Heading
          level="3"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          {timeWithTz(bookingInfo.startTime.seconds, bookingInfo?.tz)} -{" "}
          {timeWithTz(bookingInfo.endTime.seconds, bookingInfo?.tz)}
        </Heading>
      </Box>

      <Box direction="column" align="center">
        <AddToCalendarButton
          name={`${bookingInfo.serviceName} Session with Ivana & Steven Photography`}
          startDate={DateTime.fromSeconds(
            bookingInfo.startTime.seconds
          ).toISODate()}
          startTime={DateTime.fromSeconds(
            bookingInfo.startTime.seconds
          ).toLocaleString(DateTime.TIME_24_SIMPLE)}
          endTime={DateTime.fromSeconds(
            bookingInfo.endTime.seconds
          ).toLocaleString(DateTime.TIME_24_SIMPLE)}
          timeZone={bookingInfo.customerTz}
          options={["Apple", "Google", "Yahoo", "iCal"]}
          description="Thank you for booking with Ivana & Steven Photography. For any questions or concerns, please contact us at hello@ivanasteven.com"
        ></AddToCalendarButton>
      </Box>
    </Box>
  );
};
