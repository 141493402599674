import React from "react";
import { Box, Heading, Text } from "grommet";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const ServiceDetails = ({ durationId }) => {
  const currentUserSelection = useSelector(
    (state) => state.current.serviceSelected
  );
  const customSessionDetails = useSelector(
    (state) => state.current?.bookingInfo?.sessionDetails || null
  );

  return (
    <Box direction="column" gap="small">
      <Box>
        <Heading
          level="4"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          Booking Type
        </Heading>
        <Text>{currentUserSelection.serviceName}</Text>
      </Box>

      <Box>
        <Heading
          level="4"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          Session Details
        </Heading>
        <Text>
          {customSessionDetails
            ? ReactHtmlParser(customSessionDetails)
            : ReactHtmlParser(currentUserSelection.sessionDetails)}
        </Text>
      </Box>
    </Box>
  );
};

export default ServiceDetails;
