import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionPanel,
  Box,
  Page,
  ResponsiveContext,
} from "grommet";
import CalendarSelection from "./CalendarSelection";
import LoadingView from "../Common/LoadingView";
import { useDispatch, useSelector } from "react-redux";
import ServiceDetails from "./ServiceDetails";
import FooterSection from "../Common/FooterSection";
import { selectService } from "../../features/stateSlice";
import { firestore } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCompanyInfo } from "../../apis/useCompanyInfo";
import {
  addUpdateAvailabilities,
  removeAvailabilities,
} from "../../features/availSlice";
import { DateTime } from "luxon";
import HeaderContent from "../Common/HeaderContent";
import { setAddOns } from "../../features/paymentSlice";
import { Helmet } from "react-helmet";

const ServiceBookingPage = () => {
  const { serviceId, companyId } = useParams();
  useCompanyInfo(companyId);
  const [activeIndex, setActiveIndex] = useState([0]);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const size = React.useContext(ResponsiveContext);
  const companyServices = useSelector((state) => state.current.serviceSelected);

  useEffect(() => {
    document.title = "Ivana & Steven Photography - Booking Sessions";

    let unsubscribe;

    const updatePendingToAvail = async (id) => {
      const availsRef = doc(firestore, "availabilities", id);
      await updateDoc(availsRef, { status: "available", pendingTimer: null });
    };

    const getServiceInfo = async () => {
      dispatch(removeAvailabilities());
      const availRef = collection(firestore, "availabilities");
      const serviceResult = query(
        availRef,
        where("servUniqueId", "==", serviceId),
        where("startTime", ">=", Timestamp.now())
      );

      unsubscribe = onSnapshot(serviceResult, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          if (
            dataObj.status === "pending" &&
            DateTime.fromISO(dataObj.pendingTimer) < DateTime.now()
          ) {
            updatePendingToAvail(dataObj.id);
          }
          dispatch(addUpdateAvailabilities(dataObj));
        });
      });
    };

    getServiceInfo().then(() => {
      //console.log(avails);
      //updatePendingAvails(avails);
    });

    return () => unsubscribe();
  }, [dispatch, serviceId]);

  useEffect(() => {
    const q = doc(firestore, "companies", companyId, "services", serviceId);
    const addOnsRef = collection(
      firestore,
      "companies",
      companyId,
      "services",
      serviceId,
      "add-ons"
    );

    const getServiceDetail = async () => {
      try {
        const querySnapshot = await getDoc(q);
        dispatch(selectService(querySnapshot.data()));
      } catch (e) {
        console.log("Error getting cached document:", e);
      }
    };

    const getServiceAddOns = async () => {
      try {
        const addOnsResult = query(addOnsRef);
        const querySnapshot = await getDocs(addOnsResult);
        querySnapshot.forEach((doc) => {
          const dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          dispatch(setAddOns(dataObj));
        });
      } catch (e) {
        console.log("Error getting cached document:", e);
      }
    };

    if (!companyServices) {
      getServiceDetail();
      getServiceAddOns();
    }
  }, [dispatch, navigate, companyServices, companyId, serviceId]);

  useEffect(() => {
    if (companyServices) {
      setLoading(false);
    }
  }, [companyServices]);

  return loading ? (
    <LoadingView />
  ) : (
    <Page kind="narrow">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ivana & Steven Photography - Family Booking</title>
        <link
          rel="canonical"
          href="https://cx.oriangi.com/ivana-steven-photography/jwUvhqs0fn5glfpqWIqX"
        />
      </Helmet>
      <HeaderContent subtitle={true} />

      <Box direction="column" pad="medium" fill align="center">
        <Box direction="row-responsive" gap="medium" justify="center" fill>
          {size !== "small" ? (
            <Box width="medium">
              <ServiceDetails />
            </Box>
          ) : (
            <Accordion
              fill
              activeIndex={activeIndex}
              onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
            >
              <AccordionPanel label="Session info">
                <Box pad="small">
                  <ServiceDetails />
                </Box>
              </AccordionPanel>
            </Accordion>
          )}

          <CalendarSelection pageType="service" />
        </Box>
        <Box align="center" margin={{ top: "20px" }}>
          <FooterSection custom={true} social={true} />
        </Box>
      </Box>
    </Page>
  );
};

export default ServiceBookingPage;
