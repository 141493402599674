import React from "react";
import {
  Box,
  Button,
  FormField,
  TextArea,
  TextInput,
  ThemeContext,
} from "grommet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBookingPerson } from "../../features/paymentSlice";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import CustomInput from "../Common/CustomInput";

const QuestionForm = ({
  bookingDetailsPerson,
  updateActiveIndex,
  addOnsBool,
}) => {
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    updateActiveIndex();
    dispatch(setBookingPerson(data));
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box direction="column" gap="medium">
        {/* register your input into the hook by invoking the "register" function */}
        <FormField
          label="Name"
          required={{ indicator: true }}
          error={errors.name && `This field is required`}
        >
          <TextInput
            {...register("name", { required: true })}
            defaultValue={bookingDetailsPerson?.name}
          />
        </FormField>

        <FormField
          label="Email"
          required={{ indicator: true }}
          error={errors.email && `This field is required`}
        >
          <TextInput
            type="email"
            {...register("email", {
              required: true,
              //   pattern:
              //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            defaultValue={bookingDetailsPerson?.email}
          />
        </FormField>

        <FormField
          label="Phone"
          required={{ indicator: true }}
          name="Phone"
          error={
            errors.phone && errors.phone.type === "validate"
              ? `Please input a valid US phone number`
              : errors.phone && errors.phone.type === "required"
              ? `This field is required`
              : ""
          }
          htmlFor="phone"
        >
          <ThemeContext.Extend
            value={{
              textInput: {
                extend: `border: none`,
              },
            }}
          >
            <PhoneInput
              name="phone"
              control={control}
              country="US"
              rules={{ required: true, validate: isPossiblePhoneNumber }}
              defaultValue={bookingDetailsPerson?.phone}
              inputComponent={CustomInput}
            />
          </ThemeContext.Extend>
        </FormField>

        <FormField
          label="Share who will be involved in the photoshoot and more details about you!"
          required={{ indicator: true }}
        >
          <TextArea
            fill
            {...register("details")}
            defaultValue={bookingDetailsPerson?.details}
          />
        </FormField>
        {errors.details && <span>This field is required</span>}

        <Button
          primary
          label={addOnsBool ? `Next: Select Add Ons` : `Next: Payment Details`}
          type="submit"
        />
      </Box>
    </form>
  );
};

export default QuestionForm;
