import React from "react";
import { Box, Text } from "grommet";
import LoadingView from "./LoadingView";

const SessionEnded = () => {
  return (
    <Box align="center" justify="center" fill pad="large">
      <LoadingView />
      <Text>
        Your session has ended! Redirecting you back to the homepage...
      </Text>
    </Box>
  );
};

export default SessionEnded;
