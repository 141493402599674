import { createSlice } from "@reduxjs/toolkit";

export const paymentSlice = createSlice({
  name: "paymentState",
  initialState: {
    paymentState: null,
    bookingDetails: null,
    confirmation: false,
    addOns: [],
  },
  reducers: {
    selectPaymentType: (state, action) => {
      state.paymentState = action.payload;
    },
    setBookingPerson: (state, action) => {
      state.bookingDetails = action.payload;
    },
    clearPaymentType: (state, action) => {
      state.paymentState = null;
    },
    setConfirmation: (state, action) => {
      state.confirmation = action.payload;
    },
    setAddOns: {
      reducer: (state, action) => {
        if (state.addOns.length === 0) {
          state.addOns.push(action.payload);
        } else {
          state.addOns = [
            ...state.addOns.filter((arr) => arr.id !== action.payload.id),
            action.payload,
          ];
        }
      },
    },
  },
});

export const {
  selectPaymentType,
  setBookingPerson,
  clearPaymentType,
  setConfirmation,
  setAddOns,
} = paymentSlice.actions;

export default paymentSlice.reducer;
