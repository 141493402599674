import React, { useEffect, useState } from "react";
import { Box } from "grommet";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useSelector } from "react-redux";
import LoadingView from "../Common/LoadingView";
import { getFunctions, httpsCallable } from "firebase/functions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripePaymentContent = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [bookingAmountWithFee, setBookingAmountWithFee] = useState();

  const bookingAmount = useSelector(
    (state) => state.current.bookingInfo.currentPrice
  );

  useEffect(() => {
    const functions = getFunctions();
    const getPaymentIntent = httpsCallable(
      functions,
      process.env.REACT_APP_STRIPE_FUNC
    );

    const addPaymentIntent = async () => {
      const calcAmount = (((bookingAmount + 0.3) / (1 - 0.029)) * 100).toFixed(
        0
      );
      const result = await getPaymentIntent({
        amount: parseFloat(calcAmount),
      });
      setBookingAmountWithFee(calcAmount);
      setClientSecret(result.data.client_secret);
    };

    if (clientSecret === "") {
      addPaymentIntent();
    }
  }, [bookingAmount, clientSecret]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  return !clientSecret ? (
    <>
      <LoadingView />
    </>
  ) : (
    <Box>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm paymentAmount={bookingAmountWithFee} />
        </Elements>
      )}
    </Box>
  );
};

export default StripePaymentContent;
