import React, { useState } from "react";
import { Box, Grommet } from "grommet";
import { customTheme } from "./theme";
import ModalButton from "./ModalButton";
import ModalPopup from "./ModalPopup";

function Widget() {
  const [modal, setModal] = useState();

  return (
    // <Authenticator socialProviders={["google"]} signUpAttributes={["name"]}>
    // {({ signOut, user }) => (
    <Grommet theme={customTheme} full background="transparent">
      <Box fill direction="column" gap="small">
        {modal && <ModalPopup />}
        <ModalButton modalState={() => setModal(true)} />
      </Box>
    </Grommet>
    // )}
    // </Authenticator>
  );
}

export default Widget;
