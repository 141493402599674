import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Page } from "grommet";
import CalendarSelection from "../ServiceBookingPage/CalendarSelection";
import LoadingView from "../Common/LoadingView";
import { useDispatch } from "react-redux";
import FooterSection from "../Common/FooterSection";
import { firestore } from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCompanyInfo } from "../../apis/useCompanyInfo";
import {
  addUpdateAvailabilities,
  removeAvailabilities,
} from "../../features/availSlice";
import { DateTime } from "luxon";
import HeaderContent from "../Common/HeaderContent";

const GeneralListingPage = () => {
  const { companyId } = useParams();
  useCompanyInfo(companyId);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Ivana & Steven Photography - Booking Sessions";

    let unsubscribe;

    const updatePendingToAvail = async (id) => {
      const availsRef = doc(firestore, "availabilities", id);
      await updateDoc(availsRef, { status: "available", pendingTimer: null });
    };

    const getAvails = async (services) => {
      dispatch(removeAvailabilities());

      const servicesArr = services.map((item) => item.serviceId);

      const availRef = collection(firestore, "availabilities");
      const availResults = query(
        availRef,
        where("companyId", "==", companyId),
        where("serviceId", "in", servicesArr),
        where("startTime", ">=", Timestamp.now())
      );

      unsubscribe = onSnapshot(availResults, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          if (
            dataObj.status === "pending" &&
            DateTime.fromISO(dataObj.pendingTimer) < DateTime.now()
          ) {
            updatePendingToAvail(dataObj.id);
          }
          dispatch(addUpdateAvailabilities(dataObj));
        });
      });
    };

    const getAllServices = async () => {
      const serviceRef = collection(
        firestore,
        "companies",
        companyId,
        "services"
      );
      const serviceResults = query(
        serviceRef,
        where("visibility", "==", "public")
      );

      unsubscribe = onSnapshot(serviceResults, (querySnapshot) => {
        const publicServices = querySnapshot.forEach((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
        return publicServices;
      });
    };

    getAllServices().then((services) => {
      console.log(services);
      getAvails(services);
    });

    return () => unsubscribe();
  }, [dispatch, companyId]);

  // useEffect(() => {
  //   // const q = doc(firestore, "companies", companyId, "services", serviceId);
  //   // const addOnsRef = collection(
  //   //   firestore,
  //   //   "companies",
  //   //   companyId,
  //   //   "services",
  //   //   serviceId,
  //   //   "add-ons"
  //   // );

  //   const getServiceDetail = async () => {
  //     try {
  //       const querySnapshot = await getDoc(q);
  //       dispatch(selectService(querySnapshot.data()));
  //     } catch (e) {
  //       console.log("Error getting cached document:", e);
  //     }
  //   };

  //   const getServiceAddOns = async () => {
  //     try {
  //       const addOnsResult = query(addOnsRef);
  //       const querySnapshot = await getDocs(addOnsResult);
  //       querySnapshot.forEach((doc) => {
  //         const dataObj = {
  //           ...doc.data(),
  //           id: doc.id,
  //         };
  //         dispatch(setAddOns(dataObj));
  //       });
  //     } catch (e) {
  //       console.log("Error getting cached document:", e);
  //     }
  //   };

  //   if (!companyServices) {
  //     getServiceDetail();
  //     getServiceAddOns();
  //   }
  // }, [dispatch, navigate, companyServices, companyId, serviceId]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return loading ? (
    <LoadingView />
  ) : (
    <Page kind="narrow">
      <HeaderContent subtitle={true} />

      <Box direction="column" pad="medium" fill align="center">
        <Box direction="row-responsive" gap="medium" justify="center" fill>
          <CalendarSelection />
        </Box>
        <Box align="center" margin={{ top: "20px" }}>
          <FooterSection custom={true} social={true} />
        </Box>
      </Box>
    </Page>
  );
};

export default GeneralListingPage;
