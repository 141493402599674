import { createSlice } from "@reduxjs/toolkit";

export const availSlice = createSlice({
  name: "availState",
  initialState: [],
  reducers: {
    addUpdateAvailabilities: {
      reducer: (state, action) => {
        return [
          ...state.filter((arr) => arr.id !== action.payload.id),
          action.payload,
        ];
      },
      prepare: (obj) => {
        return {
          payload: {
            ...obj,
            startTime: obj.startTime.seconds,
            endTime: obj.endTime.seconds,
            createdAt: obj.createdAt?.seconds || 0,
          },
        };
      },
    },
    removeAvailabilities: {
      reducer: () => {
        return [];
      },
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUpdateAvailabilities, removeAvailabilities } =
  availSlice.actions;

export default availSlice.reducer;
