import React, { useEffect, useState } from "react";
import { Box, Calendar, Heading, Text, ThemeContext } from "grommet";
import { DateTime } from "luxon";
import AvailableTimeSlot from "./AvailabilityList";
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../../features/stateSlice";
import LoadingView from "../Common/LoadingView";
import GeneralAvailabilityList from "../GeneralListingPage/GeneralAvailabilityList";

const CalendarSelection = ({ pageType }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [calendarBounds, setCalendarBounds] = useState(null);
  const [calRef, setCalRef] = useState(null);
  const availableDates = useSelector((state) => state.avails);
  const selectedDate = useSelector((state) => state.current.dateSelected);

  useEffect(() => {
    if (availableDates.length > 0) {
      const sorted = availableDates
        .map((item) => item.startTime)
        .sort((a, b) => {
          return a - b;
        });

      setCalRef(DateTime.fromSeconds(sorted[0]).toISODate());
      setCalendarBounds([
        DateTime.fromSeconds(sorted[0]).startOf("month").toISODate(),
        DateTime.fromSeconds(sorted[0]).plus({ months: 2 }).toISODate(),
      ]);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [availableDates]);

  const calendarTheme = {
    global: {
      colors: {
        focus: "#FFF",
      },
    },
    calendar: {
      day: {
        extend: `box-shadow: 0px !important; outline: 0px !important;`,
      },
      extend: `box-shadow: 0px !important; outline: 0px !important;`,
    },
    button: {
      default: {
        color: "text",
        border: undefined,
        padding: {
          horizontal: "5px",
          vertical: "5px",
        },
      },
      primary: {
        background: { color: "brand" },
        border: undefined,
        color: "text",
      },
      secondary: {
        border: { color: "brand", width: "1px" },
        color: "text",
        font: {
          weight: "normal",
        },
      },
      active: {
        background: { color: "brand-contrast" },
        color: "text",
        secondary: {
          background: "none",
          border: {
            color: "brand-contrast",
          },
        },
      },
      disabled: {
        opacity: "0.4",
        color: "text",
        border: {
          color: "#C4C4C4",
          width: "1px",
        },
        padding: {
          horizontal: "5px",
          vertical: "5px",
        },
      },
      hover: {
        background: { color: "active" },
        secondary: {
          border: { color: "active" },
        },
      },
    },
  };

  const datesProcessed = availableDates?.map((item) =>
    // convert system iso to current local time
    DateTime.fromSeconds(item.startTime).toISODate()
  );

  return loading ? (
    <LoadingView />
  ) : (
    <Box direction="row-responsive" gap="medium">
      <Box elevation="small" pad="medium" align="center">
        <ThemeContext.Extend value={calendarTheme}>
          <Calendar
            size="medium"
            date={selectedDate}
            daysOfWeek
            bounds={calendarBounds}
            showAdjacentDays={true}
            fill
            reference={calRef}
          >
            {({ date, day, isSelected }) => {
              return (
                <ThemeContext.Extend
                  value={
                    !datesProcessed.includes(
                      DateTime.fromISO(date.toISOString()).toISODate()
                    )
                      ? { box: { extend: `cursor: default;` } }
                      : {}
                  }
                >
                  <Box
                    margin=".5em"
                    background={
                      isSelected
                        ? "calendar-selected"
                        : datesProcessed.includes(
                            DateTime.fromISO(date.toISOString()).toISODate()
                          )
                        ? "calendar-available"
                        : "white"
                    }
                    onClick={() => {
                      datesProcessed.includes(
                        DateTime.fromISO(date.toISOString()).toISODate()
                      ) && dispatch(setDate(date.toISOString()));
                    }}
                    hoverIndicator={
                      datesProcessed.includes(
                        DateTime.fromISO(date.toISOString()).toISODate()
                      ) && {
                        elevation: "medium",
                      }
                    }
                    round={true}
                    focusIndicator={false}
                  >
                    <Box
                      alignContent="center"
                      pad="small"
                      align="center"
                      fill
                      focusIndicator={false}
                    >
                      <Text
                        size="medium"
                        color={
                          !datesProcessed.includes(
                            DateTime.fromISO(date.toISOString()).toISODate()
                          )
                            ? `status-disabled`
                            : `text`
                        }
                      >
                        {day}
                      </Text>
                    </Box>
                  </Box>
                </ThemeContext.Extend>
              );
            }}
          </Calendar>
        </ThemeContext.Extend>
      </Box>
      {selectedDate && (
        <Box direction="column">
          <Heading level={4} margin={{ top: "0px" }}>
            {DateTime.fromISO(selectedDate).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
          </Heading>
          {pageType === "service" ? (
            <AvailableTimeSlot />
          ) : (
            <GeneralAvailabilityList />
          )}
        </Box>
      )}
    </Box>
  );
};

export default CalendarSelection;
