import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionPanel,
  Box,
  Heading,
  ResponsiveContext,
  Text,
} from "grommet";
import InfoSection from "./InfoSection";
import { useSelector } from "react-redux";
import LoadingView from "../Common/LoadingView";
import DetailsPanel from "./DetailsPanel";
import CountdownTimer from "./CountdownTimer";
import PaymentDetails from "./PaymentDetails";
import { useBlocker } from "../../helpers/useBlocker";
import AddOnsLandingPage from "../AddOnsPage/AddOnsLandingPage";

const renderPanelHeader = (title, active, edit) => (
  <Box
    direction="row"
    align="center"
    gap="small"
    pad={{ top: "0px", bottom: "10px" }}
  >
    <Heading
      level="4"
      margin={{
        vertical: "0",
        horizontal: "0",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
      }}
    >
      {title}
    </Heading>
    <Text color="brand">{active ? "-" : "+"}</Text>
    {edit && !active && <Text color="brand">edit</Text>}
  </Box>
);

const ReviewPage = () => {
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [addOnsSeen, setAddOnsSeen] = useState(false);
  const size = React.useContext(ResponsiveContext);
  const bookingData = useSelector((state) =>
    state.current.bookingInfo ? true : false
  );
  const bookingDetails = useSelector(
    (state) => state.payment?.bookingDetails || null
  );
  const bookingId = useSelector(
    (state) => state.current.bookingInfo?.id || null
  );
  const bookingStatus = useSelector(
    (state) => state.current.bookingInfo?.status || null
  );
  const confirmationStatus = useSelector(
    (state) => state.payment?.confirmation || null
  );

  const addOnsBool = useSelector((state) =>
    state.payment.addOns.length !== 0 ? true : false
  );

  useEffect(() => {
    document.title = "Booking Review";
    if (!bookingData) {
      setTimeout(() => {
        window.location.replace("https://ivanasteven.com");
      }, 1500);
    } else {
      setLoading(false);
    }
  }, [bookingData]);

  useBlocker(bookingId, bookingStatus, confirmationStatus, bookingData);

  const updateNoAddOnIndex = () => {
    setActiveIndex([1]);
    setAddOnsSeen(true);
  };

  return loading ? (
    <LoadingView />
  ) : (
    <Box direction="column" pad="medium" fill align="center">
      <Box align="center" margin={{ bottom: "2.5em" }}>
        <Heading level="2">Review your booking details</Heading>
        <CountdownTimer />
      </Box>

      <Box
        direction="row-responsive"
        gap="medium"
        align="start"
        fill
        justify="center"
      >
        {size !== "small" ? (
          <>
            <Box width="medium">
              <InfoSection />
            </Box>
            <Accordion
              width="medium"
              activeIndex={activeIndex}
              onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
            >
              <AccordionPanel
                header={renderPanelHeader(
                  "Your details",
                  activeIndex.includes(0),
                  bookingDetails ? true : false
                )}
              >
                <DetailsPanel
                  updateActiveIndex={() => {
                    addOnsBool ? setActiveIndex([1]) : updateNoAddOnIndex();
                  }}
                  addOnsBool={addOnsBool}
                />
              </AccordionPanel>

              {bookingDetails && addOnsBool && (
                <AccordionPanel
                  header={renderPanelHeader(
                    "Add ons",
                    activeIndex.includes(1),
                    bookingDetails ? true : false
                  )}
                >
                  <AddOnsLandingPage
                    setAddOnsSeen={() => setAddOnsSeen(true)}
                    updateActiveIndex={() => setActiveIndex([2])}
                    addOnsSeen={addOnsSeen}
                  />
                </AccordionPanel>
              )}
              {bookingDetails && addOnsSeen && (
                <AccordionPanel
                  header={renderPanelHeader(
                    "Payment details",
                    activeIndex.includes(addOnsBool ? 2 : 1),
                    false
                  )}
                  label="Payment details"
                >
                  <PaymentDetails />
                </AccordionPanel>
              )}
            </Accordion>
          </>
        ) : (
          <Box>
            <InfoSection />
            <Accordion
              activeIndex={activeIndex}
              onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
            >
              <AccordionPanel label="Your details">
                <DetailsPanel updateActiveIndex={() => setActiveIndex([1])} />
              </AccordionPanel>
              {bookingDetails && (
                <AccordionPanel label="Payment details">
                  <PaymentDetails />
                </AccordionPanel>
              )}
            </Accordion>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReviewPage;
