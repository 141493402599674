import SessionEnded from "../components/Common/SessionEnded";
import ConfirmPage from "../components/ConfirmPage/ConfirmPage";
import GeneralListingPage from "../components/GeneralListingPage/GeneralListingPage";
import PrivateBookingPage from "../components/PrivateBookingPage/PrivateBookingPage";
import ReviewPage from "../components/ReviewPage/ReviewPage";
import ServiceBookingPage from "../components/ServiceBookingPage/ServiceBookingPage";

export const childRoutes = [
  {
    path: ":companyId",
    element: <GeneralListingPage />,
  },
  {
    path: ":companyId/:serviceId",
    element: <ServiceBookingPage />,
  },
  {
    path: ":companyId/private/:availId",
    element: <PrivateBookingPage />,
  },
  {
    path: "review",
    element: <ReviewPage />,
    errorElement: <SessionEnded />,
  },
  {
    path: "confirm/:bookingId",
    element: <ConfirmPage />,
  },
];
