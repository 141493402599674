import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBEOeLrKg-5DBvzpFd8k_N5GwMjUdkc0ZU",
  authDomain: "oriangi.firebaseapp.com",
  projectId: "oriangi",
  storageBucket: "oriangi.appspot.com",
  messagingSenderId: "628125113827",
  appId: "1:628125113827:web:b5519f62f0f2ec40eb14d6",
  measurementId: "G-V12T2MK42W",
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
