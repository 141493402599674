import React, { useState } from "react";
import { Box, Text, Button, ResponsiveContext, Layer } from "grommet";
import { FormClose } from "grommet-icons";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

const TermsConditions = ({ closeLayer, acceptCheck }) => {
  const [atBottom, setAtBottom] = useState(false);
  const size = React.useContext(ResponsiveContext);

  const serviceContract = useSelector(
    (state) => state.current.serviceSelected.contract
  );
  const handleScroll = (e) => {
    if (e.offsetHeight + e.scrollTop >= e.scrollHeight) {
      setAtBottom(true);
    }
  };

  return (
    <Layer onEsc={() => closeLayer()} onClickOutside={() => closeLayer()} full>
      <Box fill>
        <Box
          direction="row"
          align="center"
          as="header"
          elevation="small"
          justify="between"
        >
          <Text margin={{ left: "small" }}>Review Terms & Conditions</Text>
          <Button icon={<FormClose />} onClick={() => closeLayer()} />
        </Box>
        <Box
          flex
          overflow="auto"
          pad="medium"
          onScroll={(e) => handleScroll(e.target)}
        >
          {ReactHtmlParser(serviceContract)}
        </Box>

        <Box
          border={{ side: "top" }}
          pad="small"
          justify="end"
          direction="row"
          align="center"
          margin={{ bottom: size === "small" ? "4.5em" : "" }}
        >
          <Button
            primary
            label="Scroll down to read and agree"
            disabled={!atBottom}
            onClick={() => {
              closeLayer();
              acceptCheck();
            }}
          />
        </Box>
      </Box>
    </Layer>
  );
};

export default TermsConditions;
