import React from "react";
import { Box, ResponsiveContext, Text, ThemeContext } from "grommet";
import { useSelector } from "react-redux";
import PhotoGallery from "./PhotoGallery";

const HeaderContent = ({ subtitle }) => {
  const compContent = useSelector(
    (state) => state.current.companyInfo?.introContent
  );
  const size = React.useContext(ResponsiveContext);

  return (
    <Box direction="column">
      <PhotoGallery />
      {subtitle && (
        <Box align="center">
          <Box
            width="auto"
            margin={{ top: "medium" }}
            pad={size === "small" ? "large" : "xsmall"}
          >
            <ThemeContext.Extend
              value={{
                text: {
                  font: {
                    family: `'Lustria', serif`,
                  },
                },
              }}
            >
              <Text textAlign="center" size="large" weight="bold">
                {compContent}
              </Text>
            </ThemeContext.Extend>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HeaderContent;
