import React from "react";
import { Box, Heading } from "grommet";
import { Clock, StatusGood } from "grommet-icons";

const PaymentDetails = ({ status }) => {
  console.log(status);
  switch (status) {
    case "new-paid":
    case "paid-confirmed":
      return (
        <Box
          alignSelf="center"
          gap="small"
          direction="row"
          margin={{ top: "10px" }}
        >
          <StatusGood />
          <Heading
            level="3"
            margin={{
              vertical: "0",
              horizontal: "0",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
            }}
          >
            Fully paid
          </Heading>
        </Box>
      );
    case "new-unpaid":
    case "new-unconfirmed":
      return (
        <>
          <Box
            alignSelf="center"
            gap="small"
            direction="row"
            margin={{ top: "10px" }}
          >
            <Clock />
            <Heading
              level="3"
              margin={{
                vertical: "0",
                horizontal: "0",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
              }}
            >
              Pending payment confirmation
            </Heading>
          </Box>
        </>
      );
    case "cancelled":
      return "Please contact hello@ivanasteven.com.";
    default:
      return;
  }
};

export default PaymentDetails;
