import React, { useState } from "react";
import { Box, Button, Heading, Text } from "grommet";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import AdjustReservation from "./AdjustReservation";

const ReservationDetails = () => {
  const [adjustRes, setAdjustRes] = useState(false);

  const sessionDetails = useSelector((state) => state.current.bookingInfo);
  let navigate = useNavigate();

  const timeFormat = { ...DateTime.TIME_SIMPLE, timeZoneName: "short" };

  return adjustRes ? (
    <Box
      direction="column"
      gap="small"
      elevation="small"
      round
      pad="medium"
      width="medium"
      justify="center"
    >
      <AdjustReservation />
      <Button label="Back" onClick={() => setAdjustRes(false)} />
    </Box>
  ) : (
    <Box
      direction="column"
      gap="small"
      elevation="small"
      round
      pad="medium"
      justify="center"
      animation="slideDown"
    >
      <Box direction="column" gap="xsmall" align="center">
        <Heading
          level="4"
          margin={{
            vertical: "0",
            horizontal: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          Your session has been reserved for
        </Heading>
        <Text>
          {DateTime.fromSeconds(sessionDetails.startTime, {
            zone: sessionDetails.tz || "America/New_York",
          }).toLocaleString(DateTime.DATE_HUGE)}
        </Text>
        <Text>
          {DateTime.fromSeconds(sessionDetails.startTime, {
            zone: sessionDetails.tz || "America/New_York",
          }).toLocaleString(DateTime.TIME_SIMPLE)}{" "}
          -{" "}
          {DateTime.fromSeconds(sessionDetails.endTime, {
            zone: sessionDetails.tz || "America/New_York",
          }).toLocaleString(timeFormat)}
        </Text>
        <Text>{sessionDetails.locations[0].name}</Text>
        <Text>{`Total Amount: $${sessionDetails.currentPrice}`}</Text>
      </Box>
      <Button
        margin={{ top: "medium" }}
        primary
        label="Purchase Reservation"
        onClick={() => {
          navigate("/review");
        }}
      />
      {/* <Button label="Adjust Reservation" onClick={() => setAdjustRes(true)} /> */}
    </Box>
  );
};

export default ReservationDetails;
