import React from "react";
import { Anchor, Box, Text } from "grommet";
import { Facebook, Instagram, Youtube } from "grommet-icons";

const FooterSection = ({ custom, social }) => {
  return (
    <Box width="medium">
      {custom && (
        <Text textAlign="center">
          Need a custom location, date or time? Contact us{" "}
          <a href="http://www.ivanasteven.com/contact">here</a>.
        </Text>
      )}
      {social && (
        <Box alignSelf="center" gap="small" direction="row">
          <Anchor
            icon={<Instagram />}
            hoverIndicator
            href="https://www.instagram.com/ivanasteven/"
            target="_blank"
          />
          <Anchor
            icon={<Facebook />}
            hoverIndicator
            href="https://www.facebook.com/ivanaandsteven/"
            target="_blank"
          />
          <Anchor
            icon={<Youtube />}
            hoverIndicator
            href="https://www.youtube.com/@ivanasteven/"
            target="_blank"
          />
        </Box>
      )}
    </Box>
  );
};

export default FooterSection;
