import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCompanyInfo } from "../../apis/useCompanyInfo";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Page,
  PageContent,
  ResponsiveContext,
  Text,
} from "grommet";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import LoadingView from "../Common/LoadingView";
import HeaderContent from "../Common/HeaderContent";
import { selectBookingSlot } from "../../features/stateSlice";
import { selectService } from "../../features/stateSlice";
import ServiceDetails from "../ServiceBookingPage/ServiceDetails";
import ReservationDetails from "./ReservationDetails";
import FooterSection from "../Common/FooterSection";
import { useNavigate } from "react-router-dom";

const PrivateBookingPage = () => {
  const { companyId, availId } = useParams();
  const [servId, setServId] = useState();
  const [name, setName] = useState();
  const [reservStatus, setReservStatus] = useState();
  const [activeIndex, setActiveIndex] = useState([0]);
  let navigate = useNavigate();

  useCompanyInfo(companyId);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const size = React.useContext(ResponsiveContext);

  useEffect(() => {
    document.title = "Ivana & Steven Photography - Reserved Booking";

    const getAvailInfo = async () => {
      const docRef = doc(firestore, "availabilities", availId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          ...data,
          id: docSnap.id,
        };
      } else {
        console.log("No document");
        Promise.reject("No availability document found");
      }
    };

    const getServiceDetail = async (servId) => {
      try {
        const q = doc(firestore, "companies", companyId, "services", servId);
        const querySnapshot = await getDoc(q);
        return querySnapshot.data();
      } catch (e) {
        console.log("Error getting cached document:", e);
        Promise.reject("No service document found");
      }
    };

    if (availId && companyId) {
      getAvailInfo().then((avail) => {
        const data = avail;

        getServiceDetail(data.servUniqueId)
          .then((serv) => {
            dispatch(selectService(serv));

            dispatch(
              selectBookingSlot({
                bookingInfo: {
                  ...data,
                  startTime: data.startTime.seconds,
                  endTime: data.endTime.seconds,
                  location: data.locations[0].name,
                  bookingDetails: serv.durations.find(
                    (d) => data.durationId === d.id
                  ).details,
                  createdAt: data.createdAt.seconds,
                },
              })
            );
            setServId(data.durationId);
            setName(data.name);
            setReservStatus(data.status);
          })
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            console.log("There's an error");
          });
      });
    }
  }, [availId, dispatch, companyId]);

  return loading ? (
    <LoadingView />
  ) : (
    <Page kind="full">
      <HeaderContent subtitle={false} />
      <PageContent>
        <Box direction="column" pad="medium" fill align="center">
          <Box direction="column" align="center" margin={{ bottom: "2em" }}>
            <Heading level="2">Hello, {name}!</Heading>
            <Text>
              We are so glad you are here and have prepared this page just for
              you!
            </Text>
          </Box>

          <Box direction="row-responsive" gap="medium" justify="center" fill>
            {size !== "small" ? (
              <Box width="medium">
                <ServiceDetails
                  durationId={servId}
                  durationSelectable={false}
                />
              </Box>
            ) : (
              <Accordion
                fill
                activeIndex={activeIndex}
                onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
              >
                <AccordionPanel label="Session info">
                  <Box pad="small">
                    <ServiceDetails
                      durationId={servId}
                      durationSelectable={false}
                    />
                  </Box>
                </AccordionPanel>
              </Accordion>
            )}

            {reservStatus !== "reserved" ? (
              <ReservationDetails />
            ) : (
              <Box direction="column" gap="xsmall" align="center">
                <Heading
                  level="4"
                  margin={{
                    vertical: "0",
                    horizontal: "0",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                  }}
                >
                  Your session is confirmed! We look forward to your upcoming
                  session.
                </Heading>

                <Button
                  margin={{ top: "medium" }}
                  primary
                  label="Go to session confirmation"
                  onClick={() => {
                    navigate(`/confirm/${availId}`);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box align="center" margin={{ top: "20px" }}>
          <FooterSection custom={false} social={true} />
        </Box>
      </PageContent>
    </Page>
  );
};

export default PrivateBookingPage;
