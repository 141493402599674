import React, { useEffect, useState } from "react";
import { Box, Heading } from "grommet";
import { useNavigate, useParams } from "react-router-dom";
import LoadingView from "../Common/LoadingView";
import { BookingDetailsRight } from "./BookingDetails";
import { firestore } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { resetState } from "../../features/stateSlice";
import { useDispatch } from "react-redux";

const ConfirmPage = () => {
  const dispatch = useDispatch();
  let params = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [bookingInfo, setBookingInfo] = useState(null);

  const sessionMessage = (status) => {
    switch (status) {
      case "new-paid":
        return "confirmed!";
      case "new-unconfirmed":
        return "reserved for 1 hour until paid!";
      case "paid-confirmed":
        return "confirmed!";
      case "cancelled":
        return "cancelled.";
      default:
        return;
    }
  };

  useEffect(() => {
    document.title = "Booking Confirmation";

    const getBookingDetails = async () => {
      const docRef = doc(firestore, "bookings", params.bookingId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setBookingInfo({
          ...docSnap.data(),
          bookingId: docSnap.id,
        });
      }
    };

    getBookingDetails();
    if (bookingInfo?.bookingId) {
      setLoading(false);
      dispatch(resetState());
    }
  }, [params, dispatch, navigate, bookingInfo?.bookingId]);

  return loading ? (
    <LoadingView />
  ) : (
    <Box direction="column" pad="medium" fill align="center">
      <Box align="center" margin={{ bottom: "20px" }}>
        <Heading level="2" textAlign="center">
          🎉 Your session is {sessionMessage(bookingInfo.bookingStatus)}
        </Heading>
      </Box>

      <BookingDetailsRight bookingInfo={bookingInfo} />
    </Box>
  );
};

export default ConfirmPage;
