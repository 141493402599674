import { Box, Button, CheckBox, Text } from "grommet";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentPrice,
  setAddOnList,
  resetCurrentPrice,
} from "../../features/stateSlice";
import ReactHtmlParser from "react-html-parser";

// a simple component that shows a list of checkboxes and a button to add them to the cart
const AddOnsLandingPage = ({
  setAddOnsSeen,
  updateActiveIndex,
  addOnsSeen,
}) => {
  const addOnsList = useSelector((state) => state.payment?.addOns || []);
  const selectedDuration = useSelector(
    (state) => state.current.bookingInfo?.durationId || null
  );

  const selectedAddOns = useSelector(
    (state) =>
      state.current.bookingInfo?.addOnList?.map((addOn) => {
        return addOn.id;
      }) || []
  );

  const filteredAddOns = addOnsList?.filter((addOn) =>
    addOn["rules-allow"].includes(selectedDuration)
  );

  const dispatch = useDispatch();

  const updateCheckoutPrice = (data) => {
    if (!data.selectedAddOns || data.selectedAddOns.length === 0) {
      dispatch(resetCurrentPrice());
      dispatch(setAddOnList([]));
    } else {
      const addOnPrices = filteredAddOns
        .filter((addOn) => data.selectedAddOns.includes(addOn.id))
        .map((addOn) => addOn.price);

      const totalAddOnPrices = addOnPrices.reduce((a, b) => a + b);

      dispatch(updateCurrentPrice(totalAddOnPrices));
      dispatch(
        setAddOnList(
          filteredAddOns.filter((addOn) =>
            data.selectedAddOns.includes(addOn.id)
          )
        )
      );
    }
  };

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    if (!data.selectedAddOns || data.selectedAddOns.length === 0) {
      reset();
    }

    setAddOnsSeen();
    updateActiveIndex();
    updateCheckoutPrice(data);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box direction="column" gap="medium" margin={{ bottom: "small" }}>
        {filteredAddOns.map((addOn) => (
          <Box direction="column" gap="small" key={addOn.id}>
            <Box direction="row" gap="medium">
              <CheckBox
                label={addOn.name}
                value={addOn.id}
                defaultChecked={
                  selectedAddOns && selectedAddOns.includes(addOn.id)
                }
                {...register("selectedAddOns")}
              />
              <Text>${addOn.price}</Text>
            </Box>
            <Text margin={{ left: "2em" }}>
              {ReactHtmlParser(addOn.description)}
            </Text>
          </Box>
        ))}
        <Button
          primary
          label={addOnsSeen ? `Update Add Ons` : `Next: Payment Details`}
          type="submit"
        />
      </Box>
    </form>
  );
};

export default AddOnsLandingPage;
