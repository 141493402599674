import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookingInfo: null,
  serviceSelected: null,
  companyInfo: null,
  serviceOptions: [],
  dateSelected: null,
  bookingSiteImages: [],
};

export const stateSlice = createSlice({
  name: "currentState",
  initialState,
  reducers: {
    resetState: (state, action) => {
      return initialState;
    },
    selectBookingSlot: (state, action) => {
      state.bookingInfo = action.payload.bookingInfo;
    },
    selectService: {
      reducer: (state, action) => {
        state.serviceSelected = action.payload;
      },
      prepare: (obj) => {
        return {
          payload: {
            ...obj,
            createdAt: obj.createdAt?.seconds || 0,
          },
        };
      },
    },
    setCompInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
    addServiceOptions: {
      reducer: (state, action) => {
        state.serviceOptions = [
          ...state.serviceOptions.filter((arr) => arr.id !== action.payload.id),
          action.payload,
        ];
      },
      prepare: (obj) => {
        return {
          payload: {
            ...obj,
            createdAt: obj.createdAt?.seconds || 0,
          },
        };
      },
    },
    setDate: (state, action) => {
      state.dateSelected = action.payload;
    },
    removeDateSelected: (state) => {
      state.dateSelected = null;
    },
    addSiteImages: (state, action) => {},
    updateCurrentPrice: (state, action) => {
      state.bookingInfo.currentPrice =
        state.bookingInfo.originalPrice + action.payload;
    },
    resetCurrentPrice: (state, action) => {
      state.bookingInfo.currentPrice = state.bookingInfo.originalPrice;
    },
    setAddOnList: (state, action) => {
      state.bookingInfo.addOnList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetState,
  selectBookingSlot,
  selectService,
  setCompInfo,
  addServiceOptions,
  setDate,
  removeDateSelected,
  updateCurrentPrice,
  resetCurrentPrice,
  setAddOnList,
} = stateSlice.actions;

export default stateSlice.reducer;
